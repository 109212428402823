/** @jsx jsx */
import { jsx, Styled } from "theme-ui"

export default ({ highlights }) => (
  <div
    sx={{
      maxWidth: ["none", 5],
      mx: [3, 3, 5],
    }}
  >
    <div className="text-content heading">
      <Styled.h2
        sx={{
          fontSize: [44, 7],
          fontWeight: "bolder",
          color: "primary",
          textTransform: "uppercase",
          marginBottom: 3,
        }}
      >
        Highlights
      </Styled.h2>
    </div>
    <div className="text-content">
      <div className="description" sx={{ display: ["block", "none"] }} />
      {highlights?.length && (
        <div
          dangerouslySetInnerHTML={{
            __html: highlights
              ?.map(
                highlight => `<p class="text-content-inner">${highlight}</p>`
              )
              .join(""),
          }}
        ></div>
      )}
    </div>
  </div>
)
