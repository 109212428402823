import React, { useState } from "react"
import Vehicle from "./vehicle"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import { generateFluidImage } from "../../utils/images"

export default ({ data }) => {
  const [vehicleData] = useState({
    ...data?.contentfulMicrositeVehicle?.vehicle?.item,
    ...data?.contentfulMicrositeVehicle?.vehicle?.item?.lot?.[0]?.auction,
    images: data?.contentfulMicrositeVehicle?.images.map(
      ({ public_id, width, height }) =>
        generateFluidImage(1800, public_id, width, height)
    ),
    video: data?.contentfulMicrositeVehicle?.videoUrl,
  })

  return (
    <div>
      <Helmet title="vehicle" defer={false} />
      <Vehicle vehicleData={vehicleData} />
    </div>
  )
}

export const pageQuery = graphql`
  query Vehicle($slug: String!) {
    contentfulMicrositeVehicle(slug: { eq: $slug }) {
      slug
      videoUrl
      images {
        width
        height
        public_id
      }

      vehicle {
        ... on ContentfulLot {
          item {
            ...Automobilia
            ...Vehicles
          }
        }

        ... on ContentfulPrivateSaleListing {
          item {
            ...Automobilia
            ...Vehicles
          }
        }
      }
    }
  }

  fragment Automobilia on ContentfulAutomobilia {
    id
    title
    __typename
    cloudinaryImagesCombined {
      public_id
      height
      width
    }

    lot {
      id
      slug
      auction {
        name
        subEvents {
          __typename
          ... on ContentfulSubEventAuction {
            startDate
          }
          ... on ContentfulSubEventViewing {
            startDate
            endDate
          }
        }
      }
    }
  }

  fragment Vehicles on ContentfulVehicle {
    title
    modelYear
    model
    make {
      name
    }
    highlights
    __typename
    cloudinaryImagesCombined {
      public_id
      height
      width
    }
    lot {
      id
      slug
      auction {
        name
        subEvents {
          __typename
          ... on ContentfulSubEventAuction {
            startDate
          }
          ... on ContentfulSubEventViewing {
            startDate
            endDate
          }
        }
      }
    }
  }
`
