/** @jsx jsx */
import { jsx, Styled } from "theme-ui"
import { getContentfulItemTitle, getAuctionYear } from "../../utils/auctionData"

export default ({ isVisible, vehicleData }) => {
  const auctionYear = getAuctionYear(vehicleData?.subEvents) ?? ""

  return (
    <div
      className={`car-specs-container ${isVisible ? "visible" : ""}`}
      sx={{
        backgroundColor: "white",
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        p: 3,
        position: "relative",
        boxSizing: "border-box",
        zIndex: 2,
      }}
    >
      <Styled.h2 sx={{ fontSize: [4, 5, 6], fontWeight: "bolder" }}>
        {getContentfulItemTitle(vehicleData)}
      </Styled.h2>
      <Styled.h4 sx={{ fontSize: [2, 3] }}>
        {auctionYear} | {vehicleData?.name}
      </Styled.h4>
    </div>
  )
}
