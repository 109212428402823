/** @jsx jsx */
import { jsx } from "theme-ui"

import TextContent from "./TextContent"
import BlueBackground from "../shared/BlueBackground"
import BackgroundImage from "../shared/BackgroundImage"

export default ({ img, highlights }) => {
  return (
    <div className="fixed-container">
      <div
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: "100vh",
        }}
      >
        <TextContent highlights={highlights} />
        <div
          sx={{
            width: "50vw",
            maxWidth: "820px",
          }}
        >
          <BackgroundImage image={img} />
        </div>
      </div>
      <BlueBackground />
    </div>
  )
}
