/** @jsx jsx */
import { jsx } from "theme-ui"
import { useState } from "react"
import BackgroundImage from "../shared/BackgroundImage"
import Arrow from "../gallery/Arrow"
import CarSpecs from "../gallery/CarSpecs"
import Img from "gatsby-image"

export default ({ images, vehicleData, reset }) => {
  const [activeImage, setActiveImage] = useState(0)
  const [isFinalPage, setFinalPage] = useState(false)
  const [isTransitioning, setTransitionState] = useState(false)

  if (reset && activeImage !== 0) {
    setTimeout(() => {
      setActiveImage(0)
      setFinalPage(false)
    }, 1000)
  }

  const handleGalleryTransition = () => {
    if (isTransitioning) {
      return false
    } else {
      setTransitionState(true)

      if (activeImage === images.length - 2) {
        setFinalPage(true)
      } else {
        setActiveImage(activeImage + 1)
      }

      setTimeout(() => {
        setTransitionState(false)
      }, 1000)
    }
  }

  return (
    <div
      id="fixed-grid-container"
      className="fixed-container"
      sx={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className="grid-gallery">
        {images.map((img, index) => (
          <div
            className={`grid-item ${
              index % 2 || index % 3 ? "landscape" : "row"
            }`}
            sx={{ height: "100%", position: "relative" }}
            key={index}
          >
            {index === images.length / 2 ? (
              <CarSpecs isVisible={true} vehicleData={vehicleData} />
            ) : (
              <Img
                fluid={img}
                alt="car"
                sx={{
                  height: "100%",
                  width: "100%",
                  objectFit: "cover",
                }}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  )
}
