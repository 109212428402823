import React, { useState } from "react"
import { ThemeProvider, Styled } from "theme-ui"

import Header from "../../components/header"
import Navigation from "../../components/navigation"
import TransitionContainer from "../../components/shared/TransitionContainer"
import Landing from "../../components/landing"
import CoreInfo from "../../components/core-info"
import Gallery from "../../components/gridGallery"
import "reset-css"
import theme from "../../theme"
import { getContentfulItemTitle } from "../../utils/auctionData"

export default ({ vehicleData }) => {
  const [activeSlide, setActiveSlide] = useState("landing")
  const slides = ["landing", "info", "grid"]
  return (
    <ThemeProvider theme={theme}>
      <Styled.root>
        <div className="vehicle-microsite">
          <Header
            showLogo={activeSlide === "landing" ? false : true}
            setActiveSlide={setActiveSlide}
            showRegisterButton={false}
          />

          <TransitionContainer
            activeSlide={activeSlide}
            setActiveSlide={setActiveSlide}
            slides={slides}
          >
            <Landing
              name="landing"
              type="vehicle"
              title={getContentfulItemTitle(vehicleData)}
              video={vehicleData?.video}
              dates={{}}
              className={vehicleData?.video ? "non-fixed-container" : null}
            />
            <CoreInfo
              name="info"
              img={vehicleData?.images?.[0]}
              highlights={vehicleData?.highlights}
            />

            <Gallery
              name="grid"
              reset={activeSlide === "info"}
              images={vehicleData?.images ?? []}
              vehicleData={vehicleData}
            />
          </TransitionContainer>

          <Navigation
            activeSlide={activeSlide}
            setActiveSlide={setActiveSlide}
            slides={slides}
          />
        </div>
      </Styled.root>
    </ThemeProvider>
  )
}
